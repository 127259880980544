import React from 'react';
import { openPopupWidget } from 'react-calendly';

const RequestButton = () => {
  return (
    <button
      className="bg-sunstate-blue rounded text-sm text-white px-8 py-3 shadow-lg border border-sunstate-blue mb-2 self-center"
      onClick={() => openPopupWidget({ url: 'https://calendly.com/sunstate' })}
    >
      Request Appointment
    </button>
  );
};

export default RequestButton;
