import React, { useState } from 'react';
// import emailjs, { init } from 'emailjs-com';

// init(process.env.GATSBY_EMAIL_USER);
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ContactForm = () => {
  const [location, setLocation] = useState('- Select One -');
  const [doctor, setDoctor] = useState('- Select One -');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [comments, setComments] = useState('');
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [overlayText, setOverlayText] = useState(
    "Thanks for reaching out, we'll be in touch."
  );
  const [bot, setBot] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checked && comments !== '' && validateEmail(email) && name !== '') {
      const form = e.target;
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          location: location,
          doctor: doctor,
          name: name,
          email: email,
          phone: phone,
          comments: comments
        })
      })
        .then(() => {
          setLocation('- Select One -');
          setDoctor('- Select One -');
          setName('');
          setEmail('');
          setPhone('');
          setComments('');
          setChecked(false);
          setLoading(false);
          setOverlayText("Thanks for reaching out, we'll be in touch.");
          setOverlay(true);
        })
        .catch((error) => {
          setOverlayText('There was an error, please try again.');
          setOverlay(true);
          setLoading(false);
        });
    } else {
      setOverlayText(
        'Please include your name, email, a comment, and check the acknowledgement.'
      );
      setOverlay(true);
      setLoading(false);
      return;
    }
  };

  return (
    <div className="w-full relative flex justify-center items-center bg-gray-200">
      <div
        className={`absolute w-full h-full items-center justify-center ${
          overlay ? 'flex' : 'hidden'
        }`}
      >
        <div className="absolute w-10/12 lg:w-1/2 flex flex-col justify-center items-center py-12 bg-white rounded-md shadow-md z-30 px-4">
          <p className="text-black text-center mb-4">{overlayText}</p>
          <button
            className="text-white uppercase text-sm w-24 rounded py-1 bg-sunstate-blue"
            disabled={loading}
            onClick={() => setOverlay(false)}
          >
            Ok
          </button>
        </div>
        <div className="absolute w-full h-full bg-black opacity-25 z-20" />
      </div>
      <div className="flex flex-col items-center justify-center py-16">
        <p className="text-gray-600 font-semibold uppercase text-lg mb-2">
          Contact Us
        </p>
        <p className="text-black font-thin text-3xl mb-2 text-center">
          Send A Message To Sunstate Medical Associates
        </p>
        <p className="text-gray-600 font-thin text-lg text-center">
          If you have any questions, concerns, or comments regarding Sunstate
          Medical Associates, please fill out the short contact form below.
        </p>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          className="w-full flex flex-row justify-center flex-wrap mt-8 px-4"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input
            className="hidden"
            name="bot-field"
            value={bot}
            onChange={(e) => setBot(e.target.value)}
          />
          <div className="w-full sm:w-72 flex flex-col justify-start items-center lg:items-start">
            <p className="text-black uppercase text-md mb-1">Location</p>
            <select
              className="w-2/3 rounded py-2 pl-2 shadow mb-2"
              name="location[]"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option>- Select One -</option>
              <option>North Sun Drive, Lake Mary, FL</option>
            </select>
            <p className="text-black uppercase text-md mb-1">Doctor</p>
            <select
              className="w-2/3 rounded py-2 pl-2 shadow mb-2"
              name="doctor[]"
              value={doctor}
              onChange={(e) => setDoctor(e.target.value)}
            >
              <option>- Select One -</option>
              <option>Farideh Zadeh, MD</option>
              <option>Zia Fatemi, MD</option>
              <option>Pothen Koruth, MD</option>
            </select>
            <p className="text-black uppercase text-md mb-1">Name</p>
            <input
              className="w-2/3 rounded py-2 pl-2 shadow mb-2"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <p className="text-black uppercase text-md mb-1">Email Address</p>
            <input
              className="w-2/3 rounded py-2 pl-2 shadow mb-2"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="text-black uppercase text-md mb-1">Phone Number</p>
            <input
              className="w-2/3 rounded py-2 pl-2 shadow mb-2"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="w-full sm:w-72 flex flex-col justify-start items-start">
            <p className="text-black uppercase text-md mb-1">Comments</p>
            <textarea
              className="w-full h-56 shadow rounded resize-none px-2 py-2 mb-4"
              name="comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
            <div className="block relative mb-4">
              <input
                type="checkbox"
                id="agreement"
                checked={!!checked}
                onChange={() => setChecked(!checked)}
              />
              <label className="text-xs pl-2 text-gray-600" htmlFor="agreement">
                I understand and agree that any information submitted will be
                forwarded to our office by email and not via a secure messaging
                system. This form should not be used to transmit private health
                information, and we disclaim all warranties with respect to the
                privacy and confidentiality of any information submitted through
                this form.
              </label>
            </div>
            <button
              type="submit"
              className={`text-white uppercase text-sm w-24 rounded py-1 ${
                loading ? 'bg-gray-400' : 'bg-sunstate-blue'
              }`}
              disabled={loading}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
