import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import RequestButton from './RequestButton.js';

const Information = () => {
  return (
    <div className="w-full border-b bg-white flex justify-center items-center">
      <div className="w-2/3 flex flex-row flex-wrap justify-around items-start py-16">
        <div className="flex flex-col justify-start items-start w-64 py-8">
          <p className="text-sm text-sunstate-blue font-semibold mb-4 uppercase">
            Location
          </p>
          <p className="text-md text-gray-600 mb-2">
            Sunstate Medical Associates
          </p>
          <p className="text-md text-gray-600 mb-2">
            758 North Sun Drive, Suite 104
          </p>
          <p className="text-md text-gray-600 mb-2">Lake Mary, FL 32746</p>
          <p className="text-md text-gray-600 mb-2">Phone: 407-318-2843</p>
          <p className="text-md text-gray-600 mb-2">Fax: 407-333-3342</p>
        </div>
        <div className="flex flex-col justify-start items-start w-64 py-8">
          <p className="text-sm text-sunstate-blue font-semibold mb-4 uppercase">
            Office Hours
          </p>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Monday</p>
            <p className="text-md text-gray-600">8:00 am - 5:00 pm</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Tuesday</p>
            <p className="text-md text-gray-600">8:00 am - 5:00 pm</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Wednesday</p>
            <p className="text-md text-gray-600">8:00 am - 5:00 pm</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Thursday</p>
            <p className="text-md text-gray-600">8:00 am - 5:00 pm</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Friday</p>
            <p className="text-md text-gray-600">8:00 am - 5:00 pm</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <p className="text-md text-gray-600">Saturday</p>
            <p className="text-md text-gray-600">Closed</p>
          </div>
          <div className="w-full flex flex-row justify-between items-center">
            <p className="text-md text-gray-600">Sunday</p>
            <p className="text-md text-gray-600">Closed</p>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-64 py-8">
          <p className="text-sm text-sunstate-blue font-semibold mb-4 uppercase">
            Get in Touch
          </p>
          <button className="w-4/5 h-12 bg-white rounded text-md sm:text-lg text-sunstate-blue sm:px-10 py-3 shadow-lg border border-sunstate-blue mb-4">
            407-318-2843
          </button>
          <div>
            <RequestButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
