import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Information from "../components/Information";
import ContactForm from "../components/ContactForm";

import "../App.css";

const Terms = () => (
  <Layout>
    <PageHeader text="Sunstate Medical Associates" />
    <Information />
    <ContactForm />
  </Layout>
);

export default Terms;
